import React, { useState } from 'react';
import styled from "styled-components";
import { DropdownButton,Dropdown } from 'react-bootstrap';


const DropdownButn=(props)=>{
 const{name,
    data,
    handleSelectedData,
    disabled

}=props
   
    return(
        <Wrapper>
           
   <DropdownButton id="dropdown-basic-button" title={name} disabled={disabled}  >
       {data&&data.map((item)=>{
           return <Dropdown.Item key={item.id} onClick={()=>handleSelectedData(item.id,item.title_en,item.code)} >{item.title_en}</Dropdown.Item>
           
       })}
  
</DropdownButton>


        </Wrapper>
    )
}
const Wrapper=styled.div`
@media only screen and (max-width: 1125px) {
    #dropdown-basic-button{
        font-size:14px;
    }
}
#dropdown-basic-button{
    background-color:transparent;
    border:none;
    color:#97999D;
    min-width:190px;
    width:auto;   
    border-radius:inherit;
    border-right:2px solid #7C7F84;
}
.btn-primary.dropdown-toggle:focus{
    box-shadow:none;
}
.dropdown-menu.show {
    background-color:#1A2029;
    color:#F3F5F7;
}
.dropdown-item{
    color:white;
}
.dropdown-item:focus{
    background-color:gray;
    opacity:0.33;
}
.dropdown-item:hover{
    background-color:gray;
}
`

export default DropdownButn;
import React, { useState } from "react";


export const selectedProvinceId_Context = React.createContext(null);
export const selectedDistrictId_Context = React.createContext(null);
export const selectedMunicipalityId_Context = React.createContext(null);
export const province_Context=React.createContext([]);
export const district_Context=React.createContext([]);
export const municipality_Context=React.createContext([]);
export const selectedProvinceCode_Context=React.createContext('');
export const selectedDistrictCode_Context=React.createContext('');
export const selectedMunicipalityCode_Context=React.createContext('');
export const selectRequest_Context=React.createContext('');
export const openRequestForm_Context=React.createContext(false);
export const selectedContent_Context=React.createContext(1);
export const toggle_context=React.createContext('request');
const Store = ({children})=>{
    const[selectedProvinceId,setSelectedProvinceId]=useState(null);
    const[selectedDistrictId,setSelectedDistrictId]=useState(null);
    const[selectedMunicipalityId,setSelectedMunicipalityId]=useState(null);
    const[province,setProvince]=useState([])
    const [district,setDistrict]=useState([])
    const [municipality,setMunicipality]=useState([]);
    const[selectedProvinceCode,setSelectedProvinceCode]=useState('');
    const[selectedDistrictCode,setSelectedDistrictCode]=useState('');
    const[selectedMunicipalityCode,setSelectedMunicipalityCode]=useState('');
    const[selectRequest,setSelectRequest]=useState(0);
    const[openRequestForm,setOpenRequestForm]=useState(false);
    const [selectedContent,setSelectedContent]=useState(1)
    const[toggle, setToggle] = useState('request');
    return(
        <selectedProvinceId_Context.Provider value={[selectedProvinceId,setSelectedProvinceId]}>
                 <selectedDistrictId_Context.Provider value={[selectedDistrictId,setSelectedDistrictId]}>
                    <selectedMunicipalityId_Context.Provider value={[selectedMunicipalityId,setSelectedMunicipalityId]}>
                        <province_Context.Provider value={[province,setProvince]}>
                            <district_Context.Provider value={[district,setDistrict]}>
                                <municipality_Context.Provider value={[municipality,setMunicipality]}>
                                    <selectedProvinceCode_Context.Provider value={[selectedProvinceCode,setSelectedProvinceCode]}>
                                        <selectedDistrictCode_Context.Provider value={[selectedDistrictCode,setSelectedDistrictCode]}>
                                            <selectedMunicipalityCode_Context.Provider value={[selectedMunicipalityCode,setSelectedMunicipalityCode]}>
                                                <selectRequest_Context.Provider value={[selectRequest,setSelectRequest]}>
                                                    <openRequestForm_Context.Provider value={[openRequestForm,setOpenRequestForm]}>
                                                        <selectedContent_Context.Provider value={[selectedContent,setSelectedContent]}>
                                                            <toggle_context.Provider value={[toggle, setToggle]}>
                                                             {children}
                                                           </toggle_context.Provider>
                                                     </selectedContent_Context.Provider>
                                                     </openRequestForm_Context.Provider>
                                              </selectRequest_Context.Provider>
                                              </selectedMunicipalityCode_Context.Provider>
                                         </selectedDistrictCode_Context.Provider>
                                    </selectedProvinceCode_Context.Provider>
                                </municipality_Context.Provider>
                            </district_Context.Provider>
                        </province_Context.Provider>
                    </selectedMunicipalityId_Context.Provider>
                 </selectedDistrictId_Context.Provider>
        </selectedProvinceId_Context.Provider>
    )

}

export default Store;
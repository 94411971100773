import React from 'react';
import './style.scss';


const Drawer=()=>{
    return(
       
         <section className="drawer-list">
            <ul>
                <li><a href="#">overview</a></li>
                <li><a href="#">OFN CHAPTERS</a></li>
                <li><a href="#">SUCCESS STORIES</a></li>
                <li><a href="#">COVID-19 RESOURCES</a></li>
                <li><a href="#">IMPORTANT LINKS</a></li>
                <li><a href="#">COLLABORATORS</a></li>
            </ul>
        </section>    
    )
}


export default Drawer;
import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import DropdownButn from '../BootstrapDropdown';
import {BiToggleLeft} from 'react-icons/bi';
import {selectedProvinceId_Context,
    selectedDistrictId_Context,
    selectedMunicipalityId_Context,
    selectedProvinceCode_Context,
    selectedDistrictCode_Context,
    selectedMunicipalityCode_Context,
    toggle_context}
    from '../../Utils/Store/Store';
const Filter=({province,district,municipality})=>{
    const[toggle, setToggle] = useContext(toggle_context)
    const[disabledDistrict,setDisabledDistrict]=useState(true);
    const[disabledMunicipality,setDisableMunicipality]=useState(true);
    const[selectedProvinceName,setSelectedProvinceName]=useState('ALL PROVINCE')
    const[selectedDistrictName,setSelectedDistrictName]=useState('ALL DISTRICT')
    const[selectedMunicipalityName,setSelectedMunicipalityName]=useState("ALL MUNICIPALITY")
    const[selectedProvinceId,setSelectedProvinceId]=useContext(selectedProvinceId_Context);
    const[selectedDistrictId,setSelectedDistrictId]=useContext(selectedDistrictId_Context);
    const[selectedMunicipalityId,setSelectedMunicipalityId]=useContext(selectedMunicipalityId_Context);
    const[filteredDistrict,setFilteredDistrict]=useState([]);
    const[filteredMunicipality,setFilteredMunicipality]=useState([]);
    const[selectedProvinceCode,setSelectedProvinceCode]=useContext(selectedProvinceCode_Context);
    const[selectedDistrictCode,setSelectedDistrictCode]=useContext(selectedDistrictCode_Context);
    const[selectedMunicipalityCode,setSelectedMunicipalityCode]=useContext(selectedMunicipalityCode_Context);
    const handleToggle = () => {
        toggle === 'request' ? setToggle('response') : setToggle('request')
    }

  
    const handleSelectedProvince=(id,name,code)=>{
        setSelectedProvinceCode(code)
        setSelectedProvinceName(name)
        setSelectedProvinceId(id)
        setSelectedDistrictCode(null)
        setSelectedDistrictName('ALL DISTRICT')
        setSelectedDistrictId(null)
        setSelectedMunicipalityCode(null)
        setSelectedMunicipalityId(null)
        setSelectedMunicipalityName("ALL MUNICIPALITY")
        setDisabledDistrict(false)
        setDisableMunicipality(true)
        setSelectedDistrictId(null)
        setSelectedMunicipalityId(null)
        setFilteredDistrict(district.filter(item=>item.province===id))

    }
    const handleSelectedDistrict=(id,name,code)=>{
        setSelectedDistrictCode(code)
        setSelectedDistrictName(name)
        setSelectedDistrictId(id)
        setSelectedMunicipalityCode(null)
        setSelectedMunicipalityId(null)
        setSelectedMunicipalityName("ALL MUNICIPALITY")
        setDisableMunicipality(false)
        setSelectedMunicipalityId(null)
        setFilteredMunicipality(municipality.filter(item=>item.district===id))
    }
    const handleSelectedMunicipality=(id,name,code)=>{
        setSelectedMunicipalityCode(code)
        setSelectedMunicipalityId(id)
        setSelectedMunicipalityName(name)
    }

    const handleClearData=()=>{
        setSelectedProvinceName('ALL PROVINCE')
        setSelectedDistrictName('ALL DISTRICT')
        setSelectedMunicipalityName("ALL MUNICIPALITY")
        setSelectedProvinceId(null)
        setSelectedDistrictId(null)
        setSelectedMunicipalityId(null)
        setDisableMunicipality(true)
        setDisabledDistrict(true)
        setSelectedProvinceCode('')
        setSelectedDistrictCode('')
        setSelectedMunicipalityCode('')



    }


    return(
        <Wrapper>
        <div className="Filter">
        { toggle === 'request'
         ? <div className='toggle' onClick={handleToggle}><BiToggleLeft/></div>
         : <div className='toggleDown' onClick={handleToggle}><BiToggleLeft/></div>
        }
         <div className='toggleLabel'>
             <div className={toggle ==='request'?'selectedToggle':'unselectedToggle'} >
             <ul   >REQUEST</ul>
             </div>
             <div className={toggle ==='response'?'selectedToggle':'unselectedToggle'}>
             <ul  >RESPONSE</ul>
             </div>  
        </div>
         <div className="Dropdown-filters" >
         <DropdownButn
          name={selectedProvinceName}
           data={province}
           handleSelectedData={handleSelectedProvince}
            disabled={false}
           />
         <DropdownButn
          name={selectedDistrictName}
           data={filteredDistrict}
            disabled={disabledDistrict}
            handleSelectedData={handleSelectedDistrict} />
         <DropdownButn
          name={selectedMunicipalityName}
           data={filteredMunicipality}
           disabled={disabledMunicipality}
           handleSelectedData={handleSelectedMunicipality}/>
         </div>
         
       {selectedProvinceId&&  <div className="clear-data" onClick={handleClearData} >CLEAR ALL</div>}
        {/* <button type="button">APPLY</button> */}
         
         </div>
       
        </Wrapper>
    )
}
const Wrapper=styled.div`
@media only screen and (max-width: 1125px) {
    .clear-data{
        font-size:14px;
    }
    .Dropdown-filters{
        margin-left:0px !important;
        
    }
}

.Filter{
    background-color:#1B212C;
    height:80px;
    color:white;
    display:flex;
    align-items:center;
    // margin-top:30px;
    // margin-bottom:30px;
    // padding-bottom:30px;
    border-bottom:2px solid #4A5964;
    .clear-data{
        margin:15px;
        cursor:pointer;
        margin-right:25px;
        color:#A2A4A8;
    }
    button{
        height:30px;
        background-color:#0A92D6;
        border-radius:25px;
        color:white;
        width:90px;
        font-weight:bold;
        border:none;
        cursor:pointer;
       
    }
    .Dropdown-filters{
        margin-left:90px;
        display:flex;
    }
   
}
.toggle{
    font-size:50px;
    -ms-transform: rotate(90deg); /* IE 9 */
    transform: rotate(90deg);
    color:#FFFFFF;
    &:hover {
        cursor: pointer;
        }
}

.toggleDown{
    margin-left:-7px;
    margin-right: 7px;
    color:#FFFFFF;
    font-size:50px;
    -ms-transform: rotate(-90deg); /* IE 9 */
    transform: rotate(-90deg);
    &:hover {
        cursor: pointer;
        }
    
}
.toggleLabel{
    display:flex;
    flex-direction: column;
  .selectedToggle:{
  background-color:red;
   }
   .unselectedToggle:{
   color:#97999D;
   }
   ul {
    font-size:14px;
    color:#97999D;
    margin: 0;
    padding:0;
}
}

}
`

export default Filter;
import React, { useContext, useEffect, useState } from 'react';
import styled from "styled-components";
import Drawer from '../Drawer';
import './style.scss'
import { Dropdown,DropdownButton } from 'react-bootstrap';
import { selectRequest_Context,openRequestForm_Context, selectedContent_Context } from '../../Utils/Store/Store';
import Modal from 'react-bootstrap/Modal'
const Nabvbar=()=>{
    const [selectedContent,setSelectedContent]=useContext(selectedContent_Context);
    const[selectRequest,setSelectRequest]=useContext(selectRequest_Context);
    const[openRequestForm,setOpenRequestForm]=useContext(openRequestForm_Context);
    const [show, setShow] = useState(false);
    const[paymentGateway,setPaymentGateway]=useState();
    const[enablePayment,setEnablePayment]=useState(false);
    

    const handleSelectRequest=(data)=>{
        setSelectRequest(data)
        setOpenRequestForm(true)
    }

const handleEnablePayment=(e)=>{
  
    setPaymentGateway(e.target.value)
    setEnablePayment(true);
    setShow(false)
}
   
    return(
        <Wrapper>
        <div className="Navbar" >
            <div className='left-navbar-data' >
                <div className={selectedContent===1?"selected-data":"single-data"} >
                <p style={{marginLeft:'20px'}} onClick={()=>setSelectedContent(1)} >OVERALL</p>
                </div>
               <div className={selectedContent===2?"selected-data":"single-data"} >
               <p onClick={()=>setSelectedContent(2)}>OXYGEN CYLINDER</p>
               </div>
                <div className={selectedContent===3?"selected-data":"single-data"} >
                <p onClick={()=>setSelectedContent(3)}>OXYGEN PLANT</p>
                </div >
                <div className={selectedContent===4?"selected-data":"single-data"} >
                <p onClick={()=>setSelectedContent(4)}>SAFETY GEAR</p>
                </div>
                {/* <div className={selectedContent===5?"selected-data":"single-data"} >
                <p onClick={()=>setSelectedContent(5)}>FOOD RELIEF</p>
                </div >
                <div className={selectedContent===6?"selected-data":"single-data"} >
                <p onClick={()=>setSelectedContent(6)}>OTHERS</p>
                </div> */}
                
                
            </div>
            <div className='right-navbar-data' >
               
                {/* <DropdownButton id="dropdown-basic-button"  title='CONTACT'>
       
            <Dropdown.Item className="test" >OXYGEN PLANT</Dropdown.Item>
            <Dropdown.Item className="test" >OXYGEN</Dropdown.Item>
            <Dropdown.Item className="test" >PPE</Dropdown.Item>
           
            </DropdownButton> */}
            <DropdownButton id="dropdown-basic-button"  title='REQUEST'  >
       
            <Dropdown.Item className="test" value="1"  onClick={()=>handleSelectRequest(1)}>OXYGEN CYLINDER</Dropdown.Item>
            <Dropdown.Item className="test" value="2" onClick={()=>handleSelectRequest(2)} >OXYGEN PLANT</Dropdown.Item>
            <Dropdown.Item className="test" value="3" onClick={()=>handleSelectRequest(3)} >SAFETY GEAR</Dropdown.Item>
             {/* <Dropdown.Item  >FOOD RELIEF</Dropdown.Item> */}
             </DropdownButton>
                <button type="button" onClick={()=>setShow(true)} >DONATE</button>
                {/* <p style={{marginRight:'20px'}}>BREADCRUMP</p> */}
                <input id="hamburger" className="hamburger" type="checkbox"/>
            <label className="hamburger" htmlFor="hamburger">
            <i></i>
             </label>
            <Drawer/>
            </div>
            <Modal
       size="md"
       show={show}
       onHide={() => setShow(false)}
      className="donationMaincontainer"
      
      
       contentClassName="mymodal"
       aria-labelledby="example-custom-modal-styling-title"
     >
       <Modal.Header closeButton>
         <Modal.Title id="example-custom-modal-styling-title">
          <div><h1>DONATE</h1></div>
         </Modal.Title>
       </Modal.Header>
       <Modal.Body>
         <div style={{display:'flex',marginBottom:'20px'}} >
             <div className="donationHeader">
                 <h5>Wire transfer</h5>
                 <h5>Branch</h5>
                 <h5>Swift Code</h5>
                 <h5>Account Name</h5>
                 <h5>Bank Account</h5>
             </div>
             <div style={{marginLeft:'auto',marginRight:'50px',}} className="donationDetails" >
                 <h5>NMB BANK</h5>
                 <h5>BOUDHA</h5>
                 <h5>NMBBNPKA</h5>
                 <h5>CREASION</h5>
                 <h5>1510142939500013</h5>
             </div>
         </div>
         <div style={{display:'flex',borderTop:'4px solid',paddingTop:'20px'}}>
             <div className="submitTitle" >
                 <h5>Smart QR,Phone Pay</h5>
                 <h5 style={{marginTop:'10px'}} >For India or China</h5>
             </div>
             <div className="submitButton" >
                 <button type="button" value='1' onClick={handleEnablePayment} >CLICK HERE</button>
                 <button type="button" value="2" onClick={handleEnablePayment} >CLICK HERE</button>
             </div>
         </div>
       </Modal.Body>
     </Modal>


     

     <Modal
       size="lg"
       show={enablePayment}
       onHide={() => setEnablePayment(false)}
      className="donationMaincontainer"
      
      
       contentClassName="mymodal"
       aria-labelledby="example-custom-modal-styling-title"
     >
       <Modal.Header closeButton>
       
       </Modal.Header>
       <Modal.Body>
            {paymentGateway==='1'?<img src="/NepalFundraising.jpeg" alt="nepal fund raising"  />:''}
        {paymentGateway==='2'?<img src="/IndiaChinaFundraising.jpg" alt="Foreign Fund Raising"  />:''    
        }
        
          
       </Modal.Body>
     </Modal> 





        </div>
     

        
        </Wrapper>
    )
}
const Wrapper=styled.div`



@media only screen and (max-width: 1125px) {
    .left-navbar-data{
        p{
            font-size:14px;
        }
    }
    .right-navbar-data{
        #dropdown-basic-button{
            font-size:14px;
        }
        button{
            font-size:14px;
            width:80px !important;
        }
    }

}
.Navbar{
    background-color:#1B212C;
    color:white;
    height:70px;
    display:flex;
    justify-content:space-between;
    border-bottom:2px solid #4A5964;
    .mymodal .modal-content{
        background-color: red;
      }
   
}

.left-navbar-data{
    display:flex;
    align-items:center;
    .single-data{
        height:100%;
        display:flex;
        align-items:center;
        
        
    }
    .selected-data{
        border-bottom:3px solid #33C2FC;
        height:100%;
        display:flex;
        align-items:center;
    }
    p{
        margin:10px;
        font-weight:bold;
        cursor:pointer;
    }
   
}
.right-navbar-data{
    display:flex;
    align-items:center;
    #dropdown-basic-button{
        background-color:transparent;
        border:none;
        color:#97999D;
        width:auto;
        border-radius:inherit;
        
    }
    .test{
        border-bottom:0.5px solid white;
    }
    .dropdown-toggle::after{
        display:none;
    }
    .dropdown-menu.show{
        background-color:#1A2029;
        color:#F3F5F7;
    }
    .dropdown-item{
        color:white;
    }
    .dropdown-item:focus{
        background-color:gray;
    }
    .dropdown-item:hover{
        background-color:gray;
    }
    .btn-primary.dropdown-toggle:focus{
        box-shadow:none;
    }
    button{
        height:30px;
        background-color:#BE3627;
        border-radius:25px;
        color:white;
        width:90px;
        font-weight:bold;
        border:none;
        cursor:pointer;
        margin:10px;
    }
    p{
        margin:10px;
        cursor:pointer;
        
    }
}
`

export default Nabvbar;



export const province = [];



export const cylinderTableData=[];
export const cylinderTableDataReducer=(state=cylinderTableData,action)=>{
  switch(action.type){
    case 'CYLINDERTABLEDATA':
      return action.payload
    default:
      return state;
  }
}
export const plantTableData=[];
export const plantTableDataReducer=(state=plantTableData,action)=>{
  switch(action.type){
    case 'PLANTTABLEDATA':
      return action.payload
    default:
      return state;
  }
}
export const safetyGearTableData=[];
export const safetyGearTableDataReducer=(state=safetyGearTableData,action)=>{
  switch(action.type){
    case 'SAFETYGEARTABLEDATA':
      return action.payload
    default:
      return state;
  }
}
export const updateMap=false;
export const updateMapReducer=(state=updateMap,action)=>{
  switch(action.type){
    case 'UPDATEMAP':
      return action.payload
    default:
      return state;
  }
}
export const oxygenCylinderData=[];
export const oxygenCylinderDataReducer=(state=oxygenCylinderData,action)=>{
  switch(action.type){
    case 'OXYGENCYLINDERDATA':
      return [action.payload]
    default:
      return state;
  }
}
export const oxygenPlantData=[];
export const oxygenPlantDataReducer=(state=oxygenPlantData,action)=>{
  switch(action.type){
    case 'OXYGENPLANTDATA':
      return [action.payload]
    default:
      return state;
  }
}
export const safetyGearData=[];
export const safetyGearReducer=(state=safetyGearData,action)=>{
  switch(action.type){
    case 'SAFETYGEARDATA':
      return [action.payload]
    default:
      return state;
  }
}
export const provinceReducer = (state = province, action) => {
  switch (action.type) {
    case "PROVINCE":
      return [action.payload]
    default:
      return state;
  }
};

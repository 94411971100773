import React, { useState,useContext,useEffect } from 'react';
import styled from "styled-components";
import {Table} from 'react-bootstrap';
import {FaCheckCircle} from 'react-icons/fa';
import {MdMore} from 'react-icons/md';
import{useSelector,useDispatch} from 'react-redux';
import { toggle_context,
    selectedProvinceId_Context,
    selectedDistrictId_Context,
    selectedMunicipalityId_Context} from '../../Utils/Store/Store';
import {setSafetyGearTableData} from '../../Utils/ReduxStore/actions';

const SafetyGear=({safetyGearData})=>{
  const dispatch=useDispatch();
    const [tableData,setTableData]=useState([]);
    const[toggle, setToggle] = useContext(toggle_context)
    const[selectedProvinceId,setSelectedProvinceId]=useContext(selectedProvinceId_Context);
    const[selectedDistrictId,setSelectedDistrictId]=useContext(selectedDistrictId_Context);
    const[selectedMunicipalityId,setSelectedMunicipalityId]=useContext(selectedMunicipalityId_Context);
    const safetyGearTableData=useSelector((state)=>state.safetyGearTableData)
    useEffect(()=>{
if(safetyGearData &&toggle==='request'){
    console.log("Entered")
    let filteredData=safetyGearData[0].filter(data=>data.type==="Request")
    if(selectedMunicipalityId){
      return  dispatch(setSafetyGearTableData(filteredData.filter(data=>data.municipality===selectedMunicipalityId)))
    }
    if(selectedDistrictId){
       return dispatch(setSafetyGearTableData(filteredData.filter(data=>data.district===selectedDistrictId)))
    }
    if(selectedProvinceId){
       return dispatch(setSafetyGearTableData(filteredData.filter(data=>data.province===selectedProvinceId)))
    }
    return dispatch(setSafetyGearTableData(filteredData))
   
}
if(safetyGearData&&toggle==='response'){
    console.log("Entered")
    let filteredData=safetyGearData[0].filter(data=>data.type==="Response")
    console.log("Than",filteredData)
    if(selectedMunicipalityId){
       return dispatch(setSafetyGearTableData(filteredData.filter(data=>data.municipality===selectedMunicipalityId)))
    }
    if(selectedDistrictId){
       return dispatch(setSafetyGearTableData(filteredData.filter(data=>data.district===selectedDistrictId)))
    }
    if(selectedProvinceId){
       return dispatch(setSafetyGearTableData(filteredData.filter(data=>data.province===selectedProvinceId)))
    }
    return dispatch(setSafetyGearTableData(filteredData))
}
    },[toggle,safetyGearData,selectedProvinceId,selectedDistrictId,selectedMunicipalityId])

console.log("Data",tableData)
    return(
        <Wrapper>
        <Table borderless hover striped  style={{backgroundColor:'#1B212C'}} responsive>
  <thead>
    <tr style={{backgroundColor:'#0C1016',color:'#98999C'}} >
      <th>CONTACT PERSON NAME</th>
      <th>ORGANIZATION NAME</th>
      <th>ORGANIZATION TYPE</th>
      <th>CONTACT NUMBER</th>
      <th>ADDRESS</th>
      <th>MASK</th>
      <th>GLOVES</th>
      <th>PPE</th>
      
     { toggle==='request'? <th>REQUESTED ON</th>:''}
     {toggle==='response'? <th>RESPONSE ON</th>:''}
    </tr>
  </thead>
  {tableData.length &&tableData.map((data)=>{
 return <tbody key={data.id}>
      
          <tr  style={{borderBottom:'1px solid #4A5964',color:'#B9BBBF'}}>
          <td>{data.contact_person_name}</td>
          <td>{data.organization_name}</td>
          <td>{data.organization_type}</td>
          <td>{data.organization_phone_number}</td>
          <td>{data.organization_address}</td>
          <td>{data.masks_quantity?data.masks_quantity:'-'}</td>
          <td>{data.gloves_quantity?data.gloves_quantity:'-'}</td>
          <td>{data.ppe_quantity?data.ppe_quantity:'-'}</td>
          {toggle==='request'? <td>{data.request_on}</td>:<td>{data.response_on}</td>}
          {/* <td className='verified'><FaCheckCircle/></td>
          <td className='more'><MdMore/></td> */}
          
        </tr>
        </tbody>
      })}
  
</Table>
{(!tableData.length&& <div style={{marginBottom:'40px'}} ><p style={{display:'flex',justifyContent:'center',color:'white',fontSize:'18px'}} >No Data Available</p></div>)}
        </Wrapper>
    )
}
const Wrapper=styled.div`
.verified{
  color:#0A92D6;
  text-align:center;
}
.more{
  text-align:center;
}
`

export default SafetyGear;
import { toast } from 'react-toastify';

const showError=(msg)=>{
    toast.error(msg)
}

 const showSuccess=(msg)=>{
    toast.success(msg)
}


export default {showSuccess,showError}
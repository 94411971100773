export const setProvince = (data) => {
    return {
      type: "PROVINCE",
      payload:data
    };
  };

  export const setOxygenCylinderData=(data)=>{
    return{
      type:'OXYGENCYLINDERDATA',
      payload:data
    }
  }
 
  export const setOxygenPlantData=(data)=>{
    return{
      type:'OXYGENPLANTDATA',
      payload:data
    }
  }
  export const setSafetyGearData=(data)=>{
    return{
      type:'SAFETYGEARDATA',
      payload:data
    }
  }
  export const setUpdateMap=(data)=>{
    return{
      type:'UPDATEMAP',
      payload:data
    }
  }
  export const setOxygenCylinderTableData=(data)=>{
    return{
      type:'CYLINDERTABLEDATA',
      payload:data
    }
  }
  export const setOxygenPlantTableData=(data)=>{
    return{
      type:'PLANTTABLEDATA',
      payload:data
    }
  }
  export const setSafetyGearTableData=(data)=>{
    return{
      type:'SAFETYGEARTABLEDATA',
      payload:data
    }
  }

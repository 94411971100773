import React, { useState,useContext,useEffect } from 'react';
import styled from "styled-components";
import {Table} from 'react-bootstrap';
import {FaCheckCircle} from 'react-icons/fa';
import {MdMore} from 'react-icons/md';
import{useSelector,useDispatch} from 'react-redux';
import { toggle_context,
    selectedProvinceId_Context,
    selectedDistrictId_Context,
    selectedMunicipalityId_Context} from '../../Utils/Store/Store';
import{setOxygenCylinderTableData} from '../../Utils/ReduxStore/actions';

const OxygenCylinderTable=({oxygenCylinderData})=>{
  const dispatch=useDispatch();
    const [tableData,setTableData]=useState([]);
    const[toggle, setToggle] = useContext(toggle_context)
    const[selectedProvinceId,setSelectedProvinceId]=useContext(selectedProvinceId_Context);
    const[selectedDistrictId,setSelectedDistrictId]=useContext(selectedDistrictId_Context);
    const[selectedMunicipalityId,setSelectedMunicipalityId]=useContext(selectedMunicipalityId_Context);
    const oxygenCylinderTableData=useSelector((state)=>state.cylinderTableData)
    useEffect(()=>{
if(oxygenCylinderData &&toggle==='request'){
    console.log("Entered")
    let filteredData=oxygenCylinderData[0].filter(data=>data.type==="Request")
    if(selectedMunicipalityId){
     return dispatch(setOxygenCylinderTableData(filteredData.filter(data=>data.municipality===selectedMunicipalityId)))
    }
    if(selectedDistrictId){
     return dispatch(setOxygenCylinderTableData(filteredData.filter(data=>data.district===selectedDistrictId)))
    }
    if(selectedProvinceId){
       return dispatch(setOxygenCylinderTableData(filteredData.filter(data=>data.province===selectedProvinceId)))
    }
    return dispatch(setOxygenCylinderTableData(filteredData))
}
if(oxygenCylinderData&&toggle==='response'){
    console.log("Entered")
    let filteredData=oxygenCylinderData[0].filter(data=>data.type==="Response")
    console.log("Than",filteredData)
    if(selectedMunicipalityId){
      return  dispatch(setOxygenCylinderTableData(filteredData.filter(data=>data.municipality===selectedMunicipalityId)))
    }
    if(selectedDistrictId){
      return  dispatch(setOxygenCylinderTableData(filteredData.filter(data=>data.district===selectedDistrictId)))
    }
    if(selectedProvinceId){
      return  dispatch(setOxygenCylinderTableData(filteredData.filter(data=>data.province===selectedProvinceId)))
    }
   
    return dispatch(setOxygenCylinderTableData(filteredData))
    
}
    },[toggle,oxygenCylinderData,selectedProvinceId,selectedDistrictId,selectedMunicipalityId])

console.log("This table Data",tableData)
    return(
        <Wrapper>
        <Table borderless hover striped  style={{backgroundColor:'#1B212C'}} responsive>
  <thead>
    <tr style={{backgroundColor:'#0C1016',color:'#98999C'}} >
      <th>PATIENT NAME</th>
      <th>AGE</th>
      <th>GENDER</th>
      <th>ADDRESS</th>
      <th>ISOLATION</th>
      <th>HOSPITAL NAME</th>
      <th>URGENCY</th>
     { toggle==='request'? <th>REQUESTED ON</th>:''}
     {toggle==='response'? <th>RESPONSE ON</th>:''}
    </tr>
  </thead>
  {tableData.length &&tableData.map((data)=>{
 return <tbody key={data.id}>
      
          <tr  style={{borderBottom:'1px solid #4A5964',color:'#B9BBBF'}}>
          <td>{data.patient_name}</td>
          <td>{data.patient_age}</td>
          <td>{data.patient_gender}</td>
          <td>{data.address}</td>
          <td>{data.patient_isolation_condition}</td>
          {data.hospital_name?<td>{data.hospital_name}</td>:<td>-</td>}
          <td>{data.urgency}</td>
          {toggle==='request'? <td>{data.request_on}</td>:<td>{data.response_on}</td>}
          {/* <td className='verified'><FaCheckCircle/></td>
          <td className='more'><MdMore/></td> */}
          
        </tr>
        </tbody>
      })}
  
</Table>
{(!tableData.length&& <div style={{marginBottom:'40px'}} ><p style={{display:'flex',justifyContent:'center',color:'white',fontSize:'18px'}} >No Data Available</p></div>)}
        </Wrapper>
    )
}
const Wrapper=styled.div`
.verified{
  color:#0A92D6;
  text-align:center;
}
.more{
  text-align:center;
}
`

export default OxygenCylinderTable;
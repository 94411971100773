import React, { useContext, useEffect, useState } from 'react';
import styled from "styled-components";
import Filter from '../../components/Filter';
import Navbar from '../../components/Navbar';
import TableData from '../../components/Table';
import LeftPane from '../../components/LeftPane';
import Map from '../../components/Map';
import HttpBrowsing from '../../Utils/BrowsingApiCall/HttpBrowsing';
import { district_Context, municipality_Context, openRequestForm_Context, province_Context, selectedContent_Context, selectRequest_Context } from '../../Utils/Store/Store';
import OxygenCylinderAddForm from '../../components/DataAdditionForm/oxygenCylinder';
import OxygenPlant from '../../components/DataAdditionForm/oxygenPlant';
import SafetyGear from '../../components/DataAdditionForm/SafetyGear';
// import Loader from "react-loader-spinner";
import{useSelector,useDispatch} from 'react-redux';
import {setOxygenCylinderData,
    setOxygenPlantData,
    setSafetyGearData,
    setUpdateMap,
    setProvince} from '../../Utils/ReduxStore/actions';
import Loader from 'react-loader';
const Dashboard=()=>{
const dispatch=useDispatch();
    const[province,setProvince]=useContext(province_Context)
    const [district,setDistrict]=useContext(district_Context)
    const [municipality,setMunicipality]=useContext(municipality_Context);
    const[selectRequest,setSelectRequest]=useContext(selectRequest_Context);
    const [selectedContent,setSelectedContent]=useContext(selectedContent_Context);
    const[loader,setLoader]=useState(true);
    // const province=useSelector((state)=>state.province)
   const oxygenCylinderData=useSelector((state)=>state.oxygencylinderData)
   const oxygenPlantData=useSelector((state)=>state.oxygenPlantData)
   const safetyGearData=useSelector((state)=>state.safetyGearData)
   
useEffect(()=>{
    HttpBrowsing.get('provinces/')
    .then((res)=>{
        // dispatch(setProvince(res.data.results))
        setProvince(res.data)
        console.log("this",res.data)
    })
    .catch(err=>{
        console.log("This error",err)
    })
    HttpBrowsing.get('districts/')
    .then((res)=>{
        setDistrict(res.data)
    })
    .catch(err=>{
        console.log("This error")
    })
    HttpBrowsing.get('municipalities/')
    .then((res)=>{
        setMunicipality(res.data.filter(item=>item.code!==null))
    })
    .catch(err=>{
        console.log("This error")
    })
   
},[])

useEffect(()=>{
    HttpBrowsing.get('/cylinders/')
    .then((res)=>{
        
        const response=res.data.map((item)=>({...item,dataCategory:'oxygen Cylinder'}))
        dispatch(setOxygenCylinderData(response))
       
    })
    .catch(err=>{
        console.log(err)
    })
    HttpBrowsing.get('/plants/')
    .then((res)=>{
       
        const response=res.data.map((item)=>({...item,dataCategory:'oxygen Plant'}))
        dispatch(setOxygenPlantData(response))
       
    })
    .catch(err=>{
        console.log(err)
    })
    HttpBrowsing.get('/gears/')
    .then((res)=>{
        
        const response=res.data.map((item)=>({...item,dataCategory:'safety gear'}))
        dispatch(setSafetyGearData(response))
        setLoader(false)
        
    })
    .catch(err=>{
        console.log(err)
    })
   
},[selectRequest])

    return(
        <Wrapper>
            
            {selectRequest===1&&   <OxygenCylinderAddForm />}
          {selectRequest===2&&  <OxygenPlant />}
          {selectRequest===3&&  <SafetyGear />}
         
            <div className="top-container" >
                {loader&&
                <div className="loading-loader" >
                <Loader/>
                <p >Loading Please Wait...</p>
                </div>}
                
            {/* <Loader className="loader" type="Oval" color="#00BFFF" height={100} width={100} /> */}
            <div className="brandLogo" >
                <img src="/oxygenNepal.png" alt="oxygenNepal logo"/>
            </div>
        <div className="mainContainer" >
          <Navbar/>
          <Filter 
          province={province}
           district={district}
           municipality={municipality}  />
          <div className="data-map" >
              <div  className="left-pane" >
                  <LeftPane/>
              </div>
              <div  className="map"  >
                  <Map/>
              </div>
          </div>
          {selectedContent!==1
          &&<TableData
           oxygenCylinderData={oxygenCylinderData}
           oxygenPlantData={oxygenPlantData}
           safetyGearData={safetyGearData} />}
          
        </div>
        <div style={{flex:1,paddingLeft:'10px',paddingRight:'10px'}}>
            
        </div>
        </div>



        
        </Wrapper>
    )
}
const Wrapper=styled.div`
// .loader{
//     position:absolute;
//     top:50%;
//     left:50%;
//     z-index:20;
// }
.loading-loader{
    p{
        z-index:999999;
        top:54%;
        position:absolute;
        left:46%;
    }
}
.loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
    z-index: 9999;
  }
@media only screen and (max-width: 1125px) {
.top-container{
    .brandLogo{
        img{
            height:60px !important;
        }
    }
}

}
.mainContainer{
    flex:14;
    padding-right:10px;
    padding-left:10px;
    background-color:#1B212C;
}
.top-container{
    display:flex;
    background-color:#1B212C;
  .brandLogo{
      display:flex;
      flex:1;
      justify-content:flex-end;
      margin-top:10px;
      padding-left:10px;
      padding-right:10px;
      img{
          height:80px;
      }
  }
}
.data-map{
    display:flex;
    flex-direction:row;
    margin-bottom:40px;
    margin-top:40px;
    .left-pane{
        color:white;
        flex:1;
        height:auto;
        background-color:#1B212C;
    }
    .map{
        color:white;
        flex:6;
        height:auto;
        background-color:#0F0F10;
        
        
    }
}

`

export default Dashboard;
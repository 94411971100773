import React, { useState,useContext,useEffect } from 'react';
import styled from "styled-components";
import {Table} from 'react-bootstrap';
import {FaCheckCircle} from 'react-icons/fa';
import {MdMore} from 'react-icons/md';
import{useSelector,useDispatch} from 'react-redux';
import { toggle_context,
    selectedProvinceId_Context,
    selectedDistrictId_Context,
    selectedMunicipalityId_Context} from '../../Utils/Store/Store';
import {setOxygenPlantTableData} from '../../Utils/ReduxStore/actions';

const OxygenPlantTable=({oxygenPlantData})=>{
  const dispatch=useDispatch();
    const [tableData,setTableData]=useState([]);
    const[toggle, setToggle] = useContext(toggle_context)
    const[selectedProvinceId,setSelectedProvinceId]=useContext(selectedProvinceId_Context);
    const[selectedDistrictId,setSelectedDistrictId]=useContext(selectedDistrictId_Context);
    const[selectedMunicipalityId,setSelectedMunicipalityId]=useContext(selectedMunicipalityId_Context);
    const oxygenPlantTableData=useSelector((state)=>state.plantTableData)
    useEffect(()=>{
if(oxygenPlantData.length&&toggle==='request'){
    let filteredData=oxygenPlantData[0].filter(data=>data.type==="Request")
    if(selectedMunicipalityId){
       return dispatch(setOxygenPlantTableData(filteredData.filter(data=>data.municipality===selectedMunicipalityId)))
    }
    if(selectedDistrictId){
      return  dispatch(setOxygenPlantTableData(filteredData.filter(data=>data.district===selectedDistrictId)))
    }
    if(selectedProvinceId){
       return dispatch(setOxygenPlantTableData(filteredData.filter(data=>data.province===selectedProvinceId)))
    }
   
     return dispatch(setOxygenPlantTableData(filteredData))
  
}
if(oxygenPlantData.length&&toggle==='response'){
    let filteredData=oxygenPlantData[0].filter(data=>data.type==="Response")
    if(selectedMunicipalityId){
       return dispatch(setOxygenPlantTableData(filteredData.filter(data=>data.municipality===selectedMunicipalityId)))
    }
    if(selectedDistrictId){
       return dispatch(setOxygenPlantTableData(filteredData.filter(data=>data.district===selectedDistrictId)))
    }
    if(selectedProvinceId){
       return dispatch(setOxygenPlantTableData(filteredData.filter(data=>data.province===selectedProvinceId)))
    }
    if(!selectedProvinceId){
     return dispatch(setOxygenPlantTableData(filteredData))
  }
}
    },[toggle,oxygenPlantData,selectedProvinceId,selectedDistrictId,selectedMunicipalityId])

console.log("this is oxygen plant",oxygenPlantData)
    return(
        <Wrapper>
        <Table borderless hover striped  style={{backgroundColor:'#1B212C'}} responsive>
  <thead>
    <tr style={{backgroundColor:'#0C1016',color:'#98999C'}} >
      <th>ORGANIZATION NAME</th>
      <th>ORGANIZATION TYPE</th>
      <th>CONTACT NUMBER</th>
      <th>ADDRESS</th>
      <th>URGENCY</th>
     { toggle==='request'? <th>REQUESTED ON</th>:''}
     {toggle==='response'? <th>RESPONSE ON</th>:''}
    </tr>
  </thead>
  <tbody>
      {oxygenPlantTableData.length &&oxygenPlantTableData.map((data)=>{
          return  <tr key={data.id} style={{borderBottom:'1px solid #4A5964',color:'#B9BBBF'}}>
          <td>{data.organization_name}</td>
          <td>{data.organization_type}</td>
          <td>{data.organization_contact_number}</td>
          <td>{data.organization_address}</td>

          <td>{data.urgency}</td>
         {toggle==='request'? <td>{data.request_on}</td>:<td>{data.response_on}</td>}
          {/* <td className='verified'><FaCheckCircle/></td>
          <td className='more'><MdMore/></td> */}
          
        </tr>
      })}
  </tbody>
</Table>
{(!tableData.length&& <div style={{marginBottom:'40px'}} ><p style={{display:'flex',justifyContent:'center',color:'white',fontSize:'18px'}} >No Data Available</p></div>)}
        </Wrapper>
    )
}
const Wrapper=styled.div`
.verified{
  color:#0A92D6;
  text-align:center;
}
.more{
  text-align:center;
}
`

export default OxygenPlantTable;
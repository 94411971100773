import React,{useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import { selectedContent_Context, toggle_context } from '../../Utils/Store/Store';
import{useSelector,useDispatch} from 'react-redux';


const LeftPane=()=>{
  const[toggle, setToggle] = useContext(toggle_context)
  const oxygenCylinderData=useSelector((state)=>state.oxygencylinderData)
  const oxygenPlantData=useSelector((state)=>state.oxygenPlantData)
  const safetyGearData=useSelector((state)=>state.safetyGearData)
  const[oxygenCylinderStatusCount,setOxygenCylinderStatusCount]=useState([])
  const[oxygenPlantStatusCount,setOxygenPlantStatusCount]=useState([]);
  const[safetyGearStatusCount,setSafetyGearStatusCount]=useState([]);
  const[overallDataStatusCount,setOverallDataStatusCount]=useState([]);
  const [selectedContent,setSelectedContent]=useContext(selectedContent_Context);
  const oxygenCylinderTableData=useSelector((state)=>state.cylinderTableData)
  const oxygenPlantTableData=useSelector((state)=>state.plantTableData)
  const safetyGearTableData=useSelector((state)=>state.safetyGearTableData)
  useEffect(()=>{
if(oxygenCylinderData.length&&toggle==='request'){
  setOxygenCylinderStatusCount(oxygenCylinderData[0].filter(data=>data.type==='Request'))
}
if(oxygenCylinderData.length && toggle==="response"){
  setOxygenCylinderStatusCount(oxygenCylinderData[0].filter(data=>data.type==='Response'))
}
if(oxygenPlantData.length&&toggle==='request'){
  setOxygenPlantStatusCount(oxygenPlantData[0].filter(data=>data.type==='Request'))
}
if(oxygenPlantData.length && toggle==="response"){
  setOxygenPlantStatusCount(oxygenPlantData[0].filter(data=>data.type==='Response'))
}
if(safetyGearData.length&&toggle==='request'){
  setSafetyGearStatusCount(safetyGearData[0].filter(data=>data.type==='Request'))
}
if(safetyGearData.length && toggle==="response"){
  setSafetyGearStatusCount(safetyGearData[0].filter(data=>data.type==='Response'))
}

  },[toggle,oxygenCylinderData,oxygenPlantData,safetyGearData,selectedContent])
 
useEffect(()=>{
  setOverallDataStatusCount([...oxygenPlantStatusCount,...oxygenCylinderStatusCount,...safetyGearStatusCount])
},[oxygenCylinderStatusCount,oxygenPlantStatusCount,safetyGearStatusCount])
    return(
        <Wrapper>
          {selectedContent===1&& (
          <>
          <div className={toggle==='request'?'info-request':'info-response'} >
        <h3>{oxygenCylinderStatusCount.length}</h3>
        {toggle==='request'?<h6>Oxygen Cylinder Requested</h6>:<h6>Oxygen Cylinder Distributed</h6>}
      </div>
      <div className={toggle==='request'?'info-request':'info-response'} >
        <h3>{oxygenPlantStatusCount.length}</h3>
        {toggle==='request'? <h6>Oxygen Plant Requested</h6>:<h6>Oxygen Plant Distributed</h6>}
      </div>
      <div className={toggle==='request'?'info-request':'info-response'} >
        <h3>{safetyGearStatusCount.length}</h3>
        {toggle==='request'?  <h6>Safety Gears Requested</h6>:<h6>Safety Gears Distributed</h6>}
      </div>
      
      <div className={toggle==='request'?'info-request':'info-response'} >
        <h3>{overallDataStatusCount.length}</h3>
        {toggle==='request'?   <h6>Request Addressed</h6>:<h6>Response Addressed</h6>}
       
      </div>
      </>)}
      {selectedContent===2&& (
          <>
          <div className={toggle==='request'?'info-request':'info-response'} >
        <h3>{oxygenCylinderTableData.length}</h3>
        {toggle==='request'?<h6>Oxygen Cylinder Requested</h6>:<h6>Oxygen Cylinder Distributed</h6>}
      </div>   
      <div className={toggle==='request'?'info-request':'info-response'} >
        <h3>{overallDataStatusCount.length}</h3>
        {toggle==='request'?   <h6>Request Addressed</h6>:<h6>Response Addressed</h6>} 
      </div>
      </>)}
      {selectedContent===3&& (
          <>
        
      <div className={toggle==='request'?'info-request':'info-response'} >
        <h3>{oxygenPlantTableData.length}</h3>
        {toggle==='request'? <h6>Oxygen Plant Requested</h6>:<h6>Oxygen Plant Distributed</h6>}
      </div>  
      <div className={toggle==='request'?'info-request':'info-response'} >
        <h3>{overallDataStatusCount.length}</h3>
        {toggle==='request'?   <h6>Request Addressed</h6>:<h6>Response Addressed</h6>}
       
      </div>
      </>)}
      {selectedContent===4&& (
          <>
      <div className={toggle==='request'?'info-request':'info-response'} >
        <h3>{safetyGearTableData.length}</h3>
        {toggle==='request'?  <h6>Safety Gears Requested</h6>:<h6>Safety Gears Distributed</h6>}
      </div>  
      <div className={toggle==='request'?'info-request':'info-response'} >
        <h3>{overallDataStatusCount.length}</h3>
        {toggle==='request'?   <h6>Request Addressed</h6>:<h6>Response Addressed</h6>}
      </div>
      </>)}
     
        </Wrapper>
    )
}
const Wrapper=styled.div`
.info-response{
  margin-bottom:20px;
  padding-right:60px;
  h3{
    color:#BE3627
  }
  h6{
    color:#A0A3A6
  }
}
.info-request{
  margin-bottom:20px;
  padding-right:60px;
  h3{
    color:#33C2FC
  }
  h6{
    color:#A0A3A6
  }
}

`

export default LeftPane;
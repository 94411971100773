import Dashboard from '../src/View/Dashboard/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Store from '../src/Utils/Store/Store';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
<Store>
<Dashboard/>
<ToastContainer />
</Store>
    
  );
}

export default App;

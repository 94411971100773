import React,{useContext} from 'react';
import { selectedContent_Context } from '../../Utils/Store/Store';
import SafetyGear from './SafetyGear';
import OxygenCylinderTable from './OxygenCylinderTable';
import OxygenPlantTable from './OxygenPlantTable';


const TableData=({oxygenCylinderData,oxygenPlantData,safetyGearData})=>{
  const [selectedContent,setSelectedContent]=useContext(selectedContent_Context);
  console.log("What table",safetyGearData)
    return(<>
    {selectedContent===2&&<OxygenCylinderTable oxygenCylinderData={oxygenCylinderData}  />}
    {selectedContent===3&&<OxygenPlantTable oxygenPlantData={oxygenPlantData}/>}
    {selectedContent===4&&<SafetyGear safetyGearData={safetyGearData} />}
    </>
    )
}


export default TableData;
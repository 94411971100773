
import { combineReducers } from "redux";
import {provinceReducer,
    oxygenCylinderDataReducer,
    oxygenPlantDataReducer,
    safetyGearReducer,
    updateMapReducer,
    safetyGearTableDataReducer,
    plantTableDataReducer,
    cylinderTableDataReducer
} from '../reducers/reducers';
const allReducers = combineReducers({
 province:provinceReducer,
 oxygencylinderData:oxygenCylinderDataReducer,
 oxygenPlantData:oxygenPlantDataReducer,
 safetyGearData:safetyGearReducer,
 updateMap:updateMapReducer,
 cylinderTableData:cylinderTableDataReducer,
 plantTableData:plantTableDataReducer,
 safetyGearTableData:safetyGearTableDataReducer

});

export default allReducers;